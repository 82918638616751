<template>
  <ModuleComponent
    ref="moduleComponent"
    name="harvestComments"
    @changed="item = $event"
    :module-data="moduleData"
    :hide-filters="hideFilters"
    :no-bread-crumb="noBreadCrumb"
  >
    <template v-slot:tableFilters="{ filters }">
      <b-container fluid>
        <b-row>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.NAME')">
              <b-form-input
                name="name"
                v-model="filters.name.value"
                :placeholder="t('GLOBAL.NAME')"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.EMAIL')">
              <b-form-input
                name="email"
                v-model="filters.email.value"
                :placeholder="t('GLOBAL.EMAIL')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.FARMER')">
              <v-select2
                v-model="farmerId"
                endPoint="accounts"
                :placeholder="t('GLOBAL.FARMER')"
                :filters="{
                  accountTypeId: { value: Constants.AccountTypes.Farmer }
                }"
                sort="name"
                @input="landId = filters.objectId.value = null"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.LAND')">
              <v-select2
                v-model="landId"
                endPoint="lands"
                :placeholder="t('GLOBAL.LAND')"
                sort="name"
                :filters="{ farmerId: { value: farmerId } }"
                @input="filters.objectId.value = null"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.CROPLAND')">
              <v-select2
                v-model="filters.objectId.value"
                endPoint="cropLands"
                :placeholder="t('GLOBAL.CROPLAND')"
                sort="name"
                :filters="{ landId: { value: landId } }"
              ></v-select2>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template v-slot:customColumn_active="{ rowItem }">
      <TableStatusColumn v-model="rowItem.active"></TableStatusColumn>
    </template>
    <template v-slot:customColumn_harvest="{ rowItem }">
      <span class="badge badge-primary mb-1">
        Farmer
      </span>
      {{ rowItem.harvest.cropLand.land.farmer.account.name }}<br />
      <span class="badge brown lighten-1 white--text mb-1">
        Arazi
      </span>
      {{ rowItem.harvest.cropLand.land.name }} ({{
        rowItem.harvest.cropLand.land.area
      }})<br />
      <span class="badge green lighten-2 white--text mb-1">
        Ekim Alanı
      </span>
      {{ rowItem.harvest.cropLand.name }}
      <br />
      <span class="badge badge-success"> Ekim Alanı Tipi</span>
      {{ rowItem.harvest.cropLand.cropLandType.name }}
    </template>
    <template v-slot:form="{ item }">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.FARMER')">
              <v-select2
                v-model="farmerId"
                endPoint="accounts"
                :placeholder="t('GLOBAL.FARMER')"
                :filters="{
                  accountTypeId: { value: Constants.AccountTypes.Farmer }
                }"
                sort="name"
                @input="landId = cropLandId = item.objectId = null"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.LAND')">
              <v-select2
                v-model="landId"
                endPoint="lands"
                :placeholder="t('GLOBAL.LAND')"
                sort="name"
                :filters="{ farmerId: { value: farmerId } }"
                @input="cropLandId = item.objectId = null"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.CROPLAND')">
              <v-select2
                v-model="cropLandId"
                endPoint="cropLands"
                :placeholder="t('GLOBAL.CROPLAND')"
                sort="name"
                :filters="{ landId: { value: landId } }"
                @input="item.objectId = null"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.HARVEST')">
              <v-select2
                v-model="item.objectId"
                endPoint="harvests"
                :placeholder="t('GLOBAL.HARVEST')"
                sort="startDate"
                :filters="{ cropLandId: { value: cropLandId } }"
              ></v-select2>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" sm="4">
            <b-form-group :label="t('GLOBAL.NAME')">
              <b-input-group>
                <b-input-group-prepend
                  ><span class="input-group-text"
                    ><i class="fa fa-user fa-lg"></i></span
                ></b-input-group-prepend>
                <b-form-input v-model="item.name"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" sm="4">
            <b-form-group :label="t('GLOBAL.EMAIL')">
              <b-input-group prepend="@">
                <b-form-input
                  name="email"
                  v-model="item.email"
                  type="email"
                  :placeholder="t('GLOBAL.EMAIL')"
                  v-validate.continues="'required|email'"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" sm="12">
            <b-form-group :label="t('GLOBAL.TITLE')">
              <b-form-input v-model="item.title"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="12" sm="12">
            <b-form-group :label="t('GLOBAL.COMMENT')">
              <b-form-textarea v-model="item.text"></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="2" sm="2">
            <b-form-group :label="t('GLOBAL.HELPFUL')">
              <b-input-group>
                <b-input-group-prepend
                  ><span class="input-group-text"
                    ><i class="fa fa-thumbs-up fa-lg"></i></span
                ></b-input-group-prepend>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="item.helpful"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="2" sm="2">
            <b-form-group :label="t('GLOBAL.UNHELPFUL')">
              <b-input-group>
                <b-input-group-prepend
                  ><span class="input-group-text"
                    ><i class="fa fa-thumbs-down fa-lg"></i></span
                ></b-input-group-prepend>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="item.unhelpful"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" sm="6">
            <b-form-group
              :label="t('GLOBAL.RATING') + ' [' + item.rating + ']'"
            >
              <v-rating v-model="item.rating"></v-rating>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3" sm="3">
            <b-form-checkbox
              size="lg"
              switch
              v-model="item.active"
              name="active"
              >{{ t("GLOBAL.ACTIVE") }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </ModuleComponent>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-Harvest
export default {
  name: "harvestComments",
  data() {
    return {
      farmerId: 0,
      landId: 0,
      cropLandId: 0,
      item: {},
      moduleData: {
        name: "harvestComments",
        primaryKey: "id",
        table: {
          sortBy: "id",
          sortDesc: true,
          graphqlQuery: `
                        id,
                        name,
                        email,
                        title,
                        rating,
                        helpful,
                        unhelpful,
                        active,
                        date,
                        harvest{
                            startDate,
                            endDate,
                            cropLand{
                                name,
                                cropLandType{name},
                                land{
                                    farmer{account{name}},
                                    name,
                                    area
                                }
                            }
                        }
                        `,
          headers: [
            { text: "ID", value: "id", width: "50" },
            {
              text: this.t("GLOBAL.HARVEST"),
              sortable: true,
              value: "harvest"
            },
            { text: this.t("GLOBAL.NAME"), sortable: true, value: "name" },
            { text: this.t("GLOBAL.EMAIL"), sortable: true, value: "email" },
            { text: this.t("GLOBAL.TITLE"), sortable: true, value: "title" },
            {
              text: this.t("GLOBAL.HELPFUL"),
              sortable: true,
              value: "helpful"
            },
            {
              text: this.t("GLOBAL.UNHELPFUL"),
              sortable: true,
              value: "unhelpful"
            },
            { text: this.t("GLOBAL.RATING"), sortable: true, value: "rating" },
            { text: this.t("GLOBAL.DATE"), sortable: true, value: "date" },
            {
              text: this.t("GLOBAL.STATUS"),
              value: "active",
              width: "60",
              align: "center"
            }
          ],
          customColumns: ["active", "harvest"],
          filters: {
            name: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            },
            email: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            },
            objectId: {
              type: "number",
              value: null
            }
          }
        },
        form: {
          fullScreen: true
        }
      }
    };
  },
  methods: {
    filterByHarvest(harvest) {
      this.moduleData.table.filters.objectId.value = harvest.id;
    }
  },

  watch: {
    item(item) {
      this.farmerId =
        item.harvest &&
        item.harvest.cropLand &&
        item.harvest.cropLand.land &&
        item.harvest.cropLand.land.farmer &&
        item.harvest.cropLand.land.farmer.account
          ? item.harvest.cropLand.land.farmer.account.id
          : this.farmerId;
      this.landId =
        item.harvest && item.harvest.cropLand && item.harvest.cropLand.land
          ? item.harvest.cropLand.land.id
          : this.landId;
      this.cropLandId =
        item.harvest && item.harvest.cropLand
          ? item.harvest.cropLand.id
          : this.cropLandId;
      this.objectId = item.harvest ? item.harvest.id : this.objectId;
    }
  }
};
</script>
